import React, { useState } from 'react';
import Button from 'react-bootstrap/Button';
import Offcanvas from 'react-bootstrap/Offcanvas'
import Link from "next/link";
import {useRouter} from "next/router";

export default function MobileMenuCanvas() {
    const [show, setShow] = useState(false);
    const rout = useRouter();
    const { query } = useRouter();
    const { locale, locales } = useRouter();
    const otherLocale = locales.filter((loc) => loc !== locale)[0];
    const pathname = rout.pathname;
    const currentQuery = Object.keys(query)
        .map((key) => `${key}=${query[key]}`)
        .join('&');

    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);

    return (
        <>
            <img className={"mobile-menu"} src={"/images/png/mobile_menu.png"} onClick={handleShow} alt="mobile-menu"/>

            <Offcanvas show={show} onHide={handleClose} placement={"end"} className="w-75 mobile-menu-canvas">

                <Offcanvas.Body>
                    <Link href="/#home">
                        <div className={`link-box`} onClick={handleClose}>
                            <a>Home</a>
                        </div>
                    </Link>
                    <Link href="/#about">
                        <div className={`link-box`} onClick={handleClose}>
                            <a>About</a>
                        </div>
                    </Link>
                    <Link href="/#services">
                        <div className={`link-box`} onClick={handleClose}>
                            <a>Services</a>
                        </div>
                    </Link>
                    <Link href="/#projects">
                        <div className={`link-box`} onClick={handleClose}>
                            <a>Projects</a>
                        </div>
                    </Link>
                    <Link href="/#partners">
                        <div className={`link-box`} onClick={handleClose}>
                            <a>Partners</a>
                        </div>
                    </Link>
                    <Link href={currentQuery ? `${pathname}?${currentQuery}` : pathname} locale={otherLocale}>
                        <div className={"language"} onClick={handleClose}>
                            <p className={`${rout.locale === 'en' ? "active" : ''}`}>EN</p>
                            <p className={`${rout.locale === 'ka' ? "active" : ''}`}>GE</p>
                        </div>
                    </Link>
                </Offcanvas.Body>
            </Offcanvas>

        </>
    );
}

import React from 'react';
import Navigation from "../Navigation";
import Footer from "../Footer";
import ScrollToTop from "../ScrollToTop";

const UserLayout = ({children}) => {

    return (
        <div className={"user-layout"}>
            <Navigation/>
            {children}
            <ScrollToTop/>
            <Footer/>
        </div>
    )
}

export default UserLayout;
import React from "react";

export default function GoogleMap(){

    return (
        // Important! Always set the container height explicitly
        <div className={"footer-map"}>
            <iframe
                src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d2977.991184515954!2d44.703767376803135!3d41.7207090712596!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x4044739cc0c3bdeb%3A0xa62be82d8783c2b5!2z4YOo4YOe4YOhIOGDoeGDneGDpOGDouGDkuGDlOGDnOGDmA!5e0!3m2!1ska!2sge!4v1702043962639!5m2!1ska!2sge"
                width="100%" height="100%" allowFullScreen="" loading="lazy"
                referrerPolicy="no-referrer-when-downgrade"/>
        </div>
    );
}

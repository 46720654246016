import React from 'react';
import Head from "next/head";

InternalServerError.layout = "InternalServerError"
export default function InternalServerError({children}) {
{
    return (
        <div className={"internal-server-error"}>
            <Head>
                <title>500</title>
            </Head>
            <h1>500</h1>
        </div>
    );
}}


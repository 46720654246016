import {useContext, useEffect, useState} from "react";
import {UserContext} from "../contexts/UserContext";
import {useRouter} from "next/router";
import AdminSideNav from "../AdminComponents/AdminSideNav";
import Spinner from 'react-bootstrap/Spinner';
import Head from "next/head";
import api from "../../utils/api";

const AdminLayout = ({children}) => {

    const router = useRouter();
    const userContext = useContext(UserContext);
    const [loading, setLoading] = useState(false)
    const [hydrated, setHydrated] = useState(false)

    useEffect(() => {
        if (!userContext?.user) {
            router.push("/admin/sign-in")
        }
        setHydrated(true)
    }, [])  // eslint-disable-line react-hooks/exhaustive-deps

    api.interceptors.request.use((req) => {
        if (typeof window !== 'undefined' && window.localStorage.getItem('user')) {
            const token = JSON.parse(window.localStorage.getItem('user'))?.token
            // @ts-ignore
            req.headers.authorization = `Bearer ${token}`;
        }
        return req;
    });

    api.interceptors.response.use(function (response) {
        // spinning hide
        setLoading(false)
        return response;
    }, function (error) {
        if (error?.response?.status === 401) {
            userContext.resetUser().then(() => router.push("/admin/sign-in"))
        }
        setLoading(false)
        return Promise.reject(error);
    });

    return ( hydrated &&
        <>
            <Head>
                <title>Softgen Admin</title>
            </Head>
            {(loading) &&
                <div className={"axios-loading"}>
                    <Spinner animation="border" role="status">
                        <span className="visually-hidden">Loading...</span>
                    </Spinner>
                </div>
            }
            <div className={"admin-layout"} style={{
                opacity: loading ? 0.5 : 1,
                pointerEvents: loading ? "none" : "auto"
            }}>
                {
                    userContext.user &&
                    <>
                        <div id={"nav"}>
                            <AdminSideNav/>
                        </div>
                        <div className={"content"}>
                            {children}
                        </div>
                    </>
                }
            </div>
        </>
    )
}

export default AdminLayout;

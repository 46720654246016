import React, {useState} from 'react';
import Button from "react-bootstrap/Button";
import {GiWingedArrow} from "react-icons/gi";

function ScrollToTop(props) {

    const [visible, setVisible] = useState(false)

    const toggleVisible = () => {
        const scrolled = document.documentElement.scrollTop;
        if (scrolled > 300) {
            setVisible(true)
        } else if (scrolled <= 300) {
            setVisible(false)
        }
    };

    const scrollToTop = () => {
        if (typeof window !== 'undefined')
            window.scrollTo({
                top: 0,
                behavior: 'smooth'
                /* you can also use 'auto' behaviour
                   in place of 'smooth' */
            });
    };

    if (typeof window !== 'undefined')
        window.addEventListener('scroll', toggleVisible);

    return (visible &&
        <div id="scroll-to-top" onClick={scrollToTop}>
            <img src={"/images/png/arrow_up.png"} alt="up"/>
        </div>
    );
}

export default ScrollToTop;
import { useRouter } from 'next/router';
import translations from './translations.json';

export default function UseTranslations () {
    const { locale } = useRouter();

    const t = (text) => translations[locale]?.[text];

    return { t, locale: locale === 'en' ? 'eng' : 'ka' };
}

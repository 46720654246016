import React, {createContext, useState} from 'react';

export const UserContext = createContext({
    user: typeof window !== 'undefined' ? JSON.parse(localStorage.getItem("user")) : {},
    setUser: () => {
    }
});

export const UserContextProvider = (props) => {

    const setUser = (user) => {
        // console.log("user", user)
        // let expireMinutes = (new Date()).setMinutes((new Date()).getMinutes() + 30);
        setState({...state, user: {...user}});
        localStorage.setItem('user', JSON.stringify({...user}));

    };

    const resetUser = async () => {
        setState({...state, user: null});
        localStorage.removeItem('user');
    };

    const [state, setState] = useState({
        user: typeof window !== 'undefined' ? JSON.parse(localStorage.getItem("user")) : {},
        setUser: setUser,
        resetUser: resetUser,
    });

    return (
        <UserContext.Provider value={state}>
            {props?.children}
        </UserContext.Provider>
    );
}
import {
    FiArrowLeftCircle,
    FiArrowRightCircle,
    FiUser,
    FiHome, FiInfo, FiTrash2, FiLogOut
} from "react-icons/fi";
import Link from "next/link";
import {useRouter} from "next/router";
import {UserContext} from "../contexts/UserContext";
import {useContext, useEffect, useRef, useState} from "react";
import {MdAdminPanelSettings} from "react-icons/md";
import {TiThMenu} from "react-icons/ti";
import useWindowSize from "../../utils/WindowSize";
import {BiNews} from "react-icons/bi";
import {AiFillProject} from "react-icons/ai";
import {ImUsers} from "react-icons/im";
import { MdSupervisedUserCircle } from "react-icons/md";


function AdminSideNav(props) {

    const router = useRouter();
    const path = router.route
    const pathArrTmp = path.split('/');
    const pathArr = pathArrTmp.slice(1);
    const [username, setUsername] = useState("")
    const context = useContext(UserContext);
    const {width} = useWindowSize()
    const sideNav = useRef(null);
    const [menuCollapse, setMenuCollapse] = useState(false);

    useEffect(() => {
        setUsername(context?.user?.email)
    }, [])  // eslint-disable-line react-hooks/exhaustive-deps

    useEffect(() => {
        if (width < 900) setMenuCollapse(true)
    }, [width])


    const menuIconClick = () => {
        menuCollapse ? setMenuCollapse(false) : setMenuCollapse(true);
    };


    const useOutsideAlerter = (ref) => {
        useEffect(() => {
            /**
             * Alert if clicked on outside of element
             */

            // Bind the event listener
            document.addEventListener("mousedown", handleClickOutside);
            return () => {
                // Unbind the event listener on clean up
                document.removeEventListener("mousedown", handleClickOutside);
            };
        }, [ref, width]);  // eslint-disable-line react-hooks/exhaustive-deps

        const handleClickOutside = (event) => {
            if (ref.current && !ref.current.contains(event.target) && (width < 900)) {
                setMenuCollapse(true)
            }
        }
    }

    useOutsideAlerter(sideNav);
    if (typeof window !== 'undefined') {
        if (!menuCollapse && width < 900) document.body.style.overflow = "hidden"
        else document.body.style.overflow = "unset"
    }


    return (
        <div className={`admin-side-nav-fake ${menuCollapse ? "collapsed" : ""}`}>
            {menuCollapse &&
                <div className={`closemenu-mobile show`} onClick={menuIconClick}>
                    <TiThMenu size={25}/>
                </div>
            }
            <div className={`admin-side-nav ${menuCollapse ? "collapsed" : ""}`} ref={sideNav}>
                <div className={"admin-side-nav__header"}>
                    {width > 900 ?
                        <div className="closemenu" onClick={menuIconClick}>
                            {menuCollapse ? <FiArrowRightCircle/> : <FiArrowLeftCircle/>}
                        </div> :
                        <div className={`closemenu-mobile collapse`} onClick={menuIconClick}>
                            <TiThMenu size={25}/>
                        </div>
                    }

                    <div className="logotext">
                        <div id="avatar-overlay">{username}</div>
                        <FiUser onClick={() => router.push("/admin/profile")} size={30}/>
                    </div>
                </div>
                <div className={"admin-side-nav__content"}>
                    <div className="menu">

                        <Link href="/admin/admin-panel">
                            <div
                                className={`menu-item ${(pathArr[0] === "admin" && pathArr[1] === "admin-panel") ? "active" : ""}`}>
                                <div className={"logo-box"}><MdAdminPanelSettings size={25}/></div>
                                {!menuCollapse && <p>AdminPanel</p>}
                            </div>
                        </Link>
                        <Link href="/admin/about-us">
                            <div
                                className={`menu-item ${(pathArr[0] === "admin" && pathArr[1] === "about-us") ? "active" : ""}`}>
                                <div className={"logo-box"}><MdSupervisedUserCircle size={25}/></div>
                                {!menuCollapse && <p>About Us</p>}
                            </div>
                        </Link>
                        <Link href="/admin/home">
                            <div
                                className={`menu-item ${(pathArr[0] === "admin" && pathArr[1] === "home") ? "active" : ""}`}>
                                <div className={"logo-box"}><FiHome size={25}/></div>
                                {!menuCollapse && <p>Home</p>}
                            </div>
                        </Link>

                        <Link href="/admin/projects">
                            <div className={`menu-item ${(pathArr[0] === "admin" && pathArr[1] === "projects") ? "active" : ""}`}>
                                <div className={"logo-box"}><AiFillProject size={25}/></div>
                                {!menuCollapse && <p>Projects</p>}
                            </div>
                        </Link>

                        <Link href="/admin/news">
                            <div
                                className={`menu-item ${(pathArr[0] === "admin" && pathArr[1] === "news") ? "active" : ""}`}>
                                <div className={"logo-box"}><BiNews size={25}/></div>
                                {!menuCollapse && <p>News</p>}
                            </div>
                        </Link>

                        <Link href="/admin/applicants">
                            <div
                                className={`menu-item ${(pathArr[0] === "admin" && pathArr[1] === "applicants") ? "active" : ""}`}>
                                <div className={"logo-box"}><ImUsers size={25}/></div>
                                {!menuCollapse && <p>Applicants</p>}
                            </div>
                        </Link>

                        {/*<Link href="/admin/else">*/}
                        {/*    <div className={"menu-item"}>*/}
                        {/*        <div className={"logo-box"}><FiTrash2 size={25}/></div>*/}
                        {/*        {!menuCollapse && <p>Else</p>}*/}
                        {/*    </div>*/}
                        {/*</Link>*/}

                    </div>
                </div>
                <div className={"admin-side-nav__footer"}>
                    <Link href="/admin/sign-out">
                        <div className={"menu-item"}>
                            <FiLogOut size={25}/>
                            {!menuCollapse && <p>Logout</p>}
                        </div>
                    </Link>
                </div>
            </div>
            <div className={`outside-background ${menuCollapse}`}/>
        </div>
    );
}

export default AdminSideNav;

import GoogleMapReact from 'google-map-react';
import GoogleMap from "./GoogleMap";
import {useRouter} from "next/router";
import Image from "next/image";
import UseTranslations from "../utils/useTranslations";
const AnyReactComponent = ({text}) => <div>{text}</div>;

function Footer(props) {
    const { t } = UseTranslations();

    const defaultProps = {
        center: {
            lat: 10.99835602,
            lng: 77.01502627
        },
        zoom: 11
    };
    const rout = useRouter();
    let path = rout.pathname.slice(1);

    const handleApiLoaded = (map, maps) => {
        // use map and maps objects
    };

    // console.log(path)
    return (
        <>
            <div className={"footer"}>
                <div className={"row"}>
                    <div className={"col one"}>
                        <div className={"footer-logo-box"}>
                            <div className={"footer-logo"}>
                                <Image src={"/images/png/softgen_logo_footer.png"} className={"logo"}
                                     alt={"softgen_logo_footer"} layout={"fill"} objectFit={"cover"} priority/>
                            </div>

                        </div>
                        <p className={"footer-logo-box__info"}>
                            {t('footer_softgen_text')}
                        </p>
                    </div>
                    <div className={"col two"}>
                        <h1 className={"footer-contact-info"}>{t('Contact Information')}</h1>
                        <p className={"footer-contact-info__location"}>{t('softgen_location')}</p>
                        <a href="tel:032 2 05 43 43" className={"footer-contact-info__phone"} style={{marginBottom: 0}}>032 2 05 43 43</a>
                        <a href="tel:+995 514 514 004" className={"footer-contact-info__phone"} style={{margin: 0}}>+995 514 514 004</a>

                    </div>
                    <div className={"col three"}>
                        <div className={"footer-social-media-icons"}>
                            <div className={"icon"}>
                                <Image alt="facebook_icon" src={"/images/png/facebook_icon.png"} layout={"fill"} objectFit={"cover"} priority/>
                            </div>
                            <div className={"icon"}>
                                <Image alt="instagram_icon" src={"/images/png/instagram_icon.png"} layout={"fill"} objectFit={"cover"} priority/>
                            </div>
                            <div className={"icon"}>
                                <Image alt="linkedin_icon" src={"/images/png/linkedin_icon.png"} layout={"fill"} objectFit={"cover"} priority/>
                            </div>
                            <div className={"icon"}>
                                <Image alt="youtube_icon" src={"/images/png/youtube_icon.png"} layout={"fill"} objectFit={"cover"} priority/>
                            </div>
                        </div>
                    </div>
                    <div className={"col four"}>
                        <GoogleMap/>
                    </div>
                </div>
                <div className={`footer-guy-container ${path}`}>
                    {path === "news" ?
                        <div className={"ladder"}>
                            <Image src={"/images/png/ladder.png"} alt="ladder" layout={"fill"} objectFit={"cover"} priority/>
                        </div>
                        : path === "news/[id]" ?
                            <div className={"news-hand"}>
                                <Image src={"/images/png/news_hand.png"} alt="news" layout={"fill"} objectFit={"cover"} priority/>
                            </div>
                            :
                            <div className={"footer-guy"}>
                                <Image src={"/images/png/footer_map_guy.png"} alt="footer-guy" layout={"fill"} objectFit={"cover"} priority/>
                            </div>
                    }
                </div>
            </div>
            <div className={"mobile-footer"}>
                <div className={"logo"}>
                    <Image src={"/images/png/softgen_logo_footer.png"} alt={"softgen_logo_footer"} layout={"fill"} objectFit={"cover"} priority/>
                </div>
                <div className={"join-info"}>
                    <h1 className={"footer-contact-info"}>{t('Contact Information')}</h1>
                    <p className={"footer-contact-info__location"} style={{maxWidth: "50%"}}>{t('softgen_location')}</p>
                    <a href="tel:032 2 05 43 43" className={"footer-contact-info__phone"} style={{marginBottom: 0}}>032
                        2 05 43 43</a>
                </div>
                <div className={"footer-social-media-icons mobile"}>
                    <div className={"icon"}>
                        <Image alt="facebook_icon" src={"/images/png/facebook_icon.png"} layout={"fill"} objectFit={"cover"} priority/>
                    </div>
                    <div className={"icon"}>
                        <Image alt="instagram_icon" src={"/images/png/instagram_icon.png"} layout={"fill"} objectFit={"cover"} priority/>
                    </div>
                    <div className={"icon"}>
                        <Image alt="linkedin_icon" src={"/images/png/linkedin_icon.png"} layout={"fill"} objectFit={"cover"} priority/>
                    </div>
                    <div className={"icon"}>
                        <Image alt="youtube_icon" src={"/images/png/youtube_icon.png"} layout={"fill"} objectFit={"cover"} priority/>
                    </div>
                </div>
                <div className={"footer-guy-container"}>
                    <div className={"footer-guy"}>
                        <Image src={"/images/png/footer_map_guy.png"} alt="footer-guy" layout={"fill"} objectFit={"cover"} priority/>
                    </div>
                </div>
            </div>
        </>

    );
}

export default Footer;

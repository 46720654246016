import Head from "next/head";

NotFound.layout = "NotFound";

export default function NotFound({children}) {
    return (
        <div className="not-found">
            <Head>
                <title>404</title>
            </Head>
            <h1>404</h1>
        </div>
    )
}



// import Result from "antd/lib/result";
// import Button from "antd/lib/button";
// import {useRouter} from 'next/router'
//
// export default function NotFound() {
//     const router = useRouter()
//     return <>
//         <Result
//             status="404"
//             title="404 not found"
//             subTitle="გვერდი ,რომელსაც თქვენ ეწვიეთ არ არსებობს !"
//             extra={<Button type="primary" onClick={() => router.back()}>უკან დაბრუნება</Button>}
//         />
//     </>
// }
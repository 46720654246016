import Link from "next/link";
import {useState} from "react";
import {useRouter} from "next/router"
import MobileMenuCanvas from "./MobileMenuCanvas";
import Image from "next/image";
import useWindowSize from "../utils/WindowSize";
import UseTranslations from "../utils/useTranslations";

function Navigation(props) {

    const rout = useRouter();
    const pathname = rout.pathname;
    const { t } = UseTranslations();
    const { query } = useRouter();
    const { locale, locales } = useRouter();
    const otherLocale = locales.filter((loc) => loc !== locale)[0];
    let path = rout.pathname.slice(1).split("/")[0];
    const {width} = useWindowSize()
    const currentQuery = Object.keys(query)
        .map((key) => `${key}=${query[key]}`)
        .join('&');


    const [isHovering, setIsHovering] = useState(false);

    const linkStyleKa = {
        fontSize: width < 1100 ? 13 : 15,
        padding: '75px 0px 18px 0px' ,
        lineHeight: '30px'
    };
    const handleMouseOver = () => {
        setIsHovering(true);
    };

    const handleMouseOut = () => {
        setIsHovering(false);
    };
    return (
        <nav className={"nav"} id={"home"}>
            <div className={"nav-inside"}>
                {!(locale === 'ka' && width<1300) &&
                    <div className={"logo"}>
                        <Image priority src={"/images/png/softgen_logo.png"} alt="softgen_logo" layout={"fill"}
                               objectFit={"cover"}
                               onClick={() => rout.push("/")}/>
                    </div>
                }
                <div className={"links"}>
                    <Link href="/#home">
                        <div className={`link-box not-default ${!isHovering && path === '' && "active"}`}
                             onMouseOver={handleMouseOver} onMouseOut={handleMouseOut}>
                            <a href={'/home'} style={locale === 'ka' ? linkStyleKa : undefined}>{t('Home')}</a>
                        </div>
                    </Link>
                    <Link href="/#news">
                        <div className={`link-box not-default ${!isHovering && path === 'news' && "active"}`}
                             onMouseOver={handleMouseOver} onMouseOut={handleMouseOut}>
                            <a href={'/news'} style={locale === 'ka' ? linkStyleKa : undefined}>{t('News')}</a>
                        </div>
                    </Link>
                    <Link href="/#about">
                        <div className={`link-box not-default`} onMouseOver={handleMouseOver} onMouseOut={handleMouseOut}>
                            <a href={'/about'} style={locale === 'ka' ? linkStyleKa : undefined}>{t('About')}</a>
                        </div>
                    </Link>
                    <Link href="/#services">
                        <div className={`link-box not-default`} onMouseOver={handleMouseOver} onMouseOut={handleMouseOut}>
                            <a href={'/services'} style={locale === 'ka' ? linkStyleKa : undefined}>{t('Services')}</a>
                        </div>
                    </Link>
                    <Link href="/projects">
                        <div className={`link-box not-default ${!isHovering && path === 'projects' && "active"}`}
                             onMouseOver={handleMouseOver} onMouseOut={handleMouseOut}>
                            <a href={'/projects'} style={locale === 'ka' ? linkStyleKa : undefined}>{t('Projects')}</a>
                        </div>
                    </Link>
                    {/*<Link href="/#team">*/}
                    {/*    <div className={`link-box not-default ${!isHovering && path === 'team' && "active"}`}*/}
                    {/*         onMouseOver={handleMouseOver} onMouseOut={handleMouseOut}>*/}
                    {/*        <a href={'/team'} style={locale === 'ka' ? linkStyleKa : undefined}>{t('Team')}</a>*/}
                    {/*    </div>*/}
                    {/*</Link>*/}
                    <Link href="/#partners">
                        <div className={`link-box not-default`} onMouseOver={handleMouseOver} onMouseOut={handleMouseOut}>
                            <a href={'/partners'} style={locale === 'ka' ? linkStyleKa : undefined}>{t('Partners')}</a>
                        </div>
                    </Link>
                </div>

                <div className={"right-side"}>
                    {/* <div className={"sign"}>
                    <div className={"sign__up"}>Sign up</div>
                    <div className={"sign__in"}>Sign in</div>
                </div>*/}
                    <Link href={currentQuery ? `${pathname}?${currentQuery}` : pathname} locale={otherLocale}>
                        <div className={"language"}>
                            <p className={`${rout.locale === 'en' ? "active" : ''}`}>EN</p>
                            <p className={`${rout.locale === 'ka' ? "active" : ''}`}>GE</p>
                        </div>
                    </Link>
                </div>
                <MobileMenuCanvas/>
            </div>

        </nav>
    );
}

export default Navigation;
